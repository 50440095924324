import React, { useState} from 'react'
import { CSSTransition } from 'react-transition-group';
import classNames from "classnames"
import "react-datepicker/dist/react-datepicker.css";
import Alert from "./alert"
import Autocomplete from "../components/autocomplete"

const TransitionDestiEscorxador = props => {
  const [destiEscorxadorSelected,setDestiEscorxadorSelected] =useState("");
  const [destiEscorxadorStringSelected,setDestiEscorxadorStringSelected] =useState("");
  const [showAlertStep, setShowAlertStep] = useState(false);
  const [messageAlertStep, setMessageAlertStep] = useState("");
 
  const handleDestiEscorxador= (text,index) =>{
    let granja= text.split("-");
    let code= granja[0].trim();
    let granjaText=granja[1].trim()
    code = code.replace(/^\s+/g, '');
    granjaText=granjaText.replace(/^\s+/g, '');

    setDestiEscorxadorSelected(code)
    setDestiEscorxadorStringSelected(code+"-"+granjaText)
  }


  const closeAlertStep = event => {
    setShowAlertStep(false)
  }
  
  const onExit = () =>{
    var res=[];
    res.push({
      "key":"Desti",
      "value": destiEscorxadorStringSelected
    })
     
    props.callbackValues.destiEscorxadorSelected=destiEscorxadorSelected;
    props.callbackStrings(res)
  }

  const handleClickUp = (e) => {
    e.preventDefault();
   
    if (!destiEscorxadorSelected){
      setMessageAlertStep("Has de seleccionar un Destí escorxador ")
      setShowAlertStep(true)
      return
    }
    setShowAlertStep(false)
    props.handleStepActiveUp(props.stepActive)
  }

  const handleClickDown = (e) => {
    e.preventDefault();
    props.handleStepActiveDown(props.stepActive)
  }
  const objectGranjes = Object.entries(props.arrayData);
  //console.log("ESCORXADORS "+JSON.stringify(objectGranjes))
  var ArrayGranjesAutocomplete = [];
  objectGranjes.forEach(([key, value]) => {
    //console.log("key "+key+", value="+JSON.stringify(value))
    var item = {};
    item.value = value.id;
    item.label = value.id + "-" + value.NOM;
    ArrayGranjesAutocomplete.push(item);
  });

  return (
   <span>   
      <CSSTransition
        in={props.stepActive === props.index}
        timeout={300}
        classNames={props.stepPrev<props.stepActive?'steptransitionright':'steptransitionleft'}
        unmountOnExit
        onExit={onExit}
      >
            <div className="steps-panel">
               
              <Autocomplete suggestions={ArrayGranjesAutocomplete} inputLabel="Destí escorxador" inputName="desti-destete" callbackValue={handleDestiEscorxador}/>
      
                <div className="steps-nav">
                  <button onClick={handleClickDown} className={props.stepActive <= 1 ? classNames('disabled', 'steps-nav-btn', 'steps-nav-btn-prev') : classNames('steps-nav-btn', 'steps-nav-btn-prev')}>
                    <span>Prev</span>
                  </button>
                  <button onClick={handleClickUp} className={props.stepActive >= 5 ? classNames('disabled', 'steps-nav-btn steps-nav-btn-next') : 'steps-nav-btn steps-nav-btn-next'}>
                    <span>Next</span>
                  </button>
                </div>
              </div>
    </CSSTransition>
    <CSSTransition
      in={showAlertStep}
      timeout={300}
      classNames={'alertright'}
      unmountOnExit >
      <Alert type="error">
        <div className="alert-message">
        {messageAlertStep}
        </div>
        <span className="alert-close" onClick={closeAlertStep}>
          <i className="icon-close"></i>
        </span>
      </Alert>
  </CSSTransition>
</span>)}
export default TransitionDestiEscorxador