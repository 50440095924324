import React, { useState} from 'react'
import { CSSTransition } from 'react-transition-group';
import classNames from "classnames"
import "react-datepicker/dist/react-datepicker.css";
import Alert from "../components/alert"
import Autocomplete from "../components/autocomplete"

const TransitionGranjesMares = props => {
  const [origenMaresSelected,setOrigenMaresSelected] =useState("");
  const [origenMaresStringSelected, setOrigenMaresStringSelected] = useState("");
  const [nauOrigen, setNauOrigen] = useState("")
  const [showAlertStep, setShowAlertStep] = useState(false);
  
  const handleSelectOrigenMaresChange= (text,index) =>{
    let granja= text.split("-");
    let code= granja[0].trim();
    let granjaText=granja[1].trim()
    code = code.replace(/^\s+/g, '');
    granjaText=granjaText.replace(/^\s+/g, '');
  //  console.log("granjaText index="+index+","+props.arrayData[index].idNau)
    setOrigenMaresSelected(code)
    setOrigenMaresStringSelected(code+"-"+granjaText)
    if (!text)  setNauOrigen("")
     else setNauOrigen(props.arrayData[index].idNau)
  }
  const closeAlertStep = event => {
    setShowAlertStep(false)
  }
  const onExit = () =>{
    var res=[];
    res.push({
      "key":"Origen",
      "value": origenMaresStringSelected
    })
    props.callbackValues["origenMaresSelected"]=origenMaresSelected;
    props.callbackValues["nauOrigen"]=nauOrigen;
    props.callbackStrings(res)
  }

  const handleClickUp = (e) => {
    e.preventDefault();
    if(props.stepActive===props.index && !origenMaresSelected) {
        setShowAlertStep(true)
    }else {
      props.handleStepActiveUp(props.stepActive)
      setShowAlertStep(false)
    }
  }

  const handleClickDown = (e) => {
    e.preventDefault();
    props.handleStepActiveDown(props.stepActive)
    return
  }

  const objectGranjes = Object.entries(props.arrayData);
  var ArrayGranjesAutocomplete = [];
  objectGranjes.forEach(([key, value]) => {
    var item = {};
    item.value = value.marca;
    item.label = value.marca + "-" + value.nomMarca;
    ArrayGranjesAutocomplete.push(item);
  });

  return (
    <>
      <CSSTransition
        in={props.stepActive === props.index}
        timeout={300}
        onExit={onExit}
        classNames={props.stepPrev<props.stepActive?'steptransitionright':'steptransitionleft'}
        unmountOnExit
      >
        <div className="steps-panel">
          <Autocomplete suggestions={ArrayGranjesAutocomplete} inputLabel="Origen mares" inputName="origen-mares" callbackValue={handleSelectOrigenMaresChange}/>
        
          <div className="steps-nav">
            <button onClick={handleClickDown} className={props.stepActive <= 1 ? classNames('disabled', 'steps-nav-btn', 'steps-nav-btn-prev') : classNames('steps-nav-btn', 'steps-nav-btn-prev')}>
              <span>Prev</span>
            </button>
            <button onClick={handleClickUp} className='steps-nav-btn steps-nav-btn-next'>
              <span>Next</span>
            </button>
          </div>
        </div>
      </CSSTransition>
      <CSSTransition
        in={showAlertStep}
        timeout={300}
        classNames={'alertright'}
        unmountOnExit
      >
        <Alert type="error">
          <div className="alert-message">
            El camp Origen Mares és obligatori
          </div>
          <span className="alert-close" onClick={closeAlertStep}>
            <i className="icon-close"></i>
          </span>
        </Alert>
      </CSSTransition>
    </>
    )
}

export default TransitionGranjesMares